<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <a-button type="primary" @click="$refs.dialog.isShow = true">新建</a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <!--标签-->
    <div>
      <a-tabs default-active-key="ALL" @change="callback" :activeKey="activeKey">
        <a-tab-pane v-for="item in appTags" :key="item.tagCode" :tab="item.tagName"/>
      </a-tabs>
    </div>
    <div class="app-container">
      <a-card v-for="(item,index) in list" :key="index" class="app-card" hoverable>
        <img
          slot="cover"
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
        <template slot="actions" class="ant-card-actions">
          <a-icon key="edit" type="edit" @click="$refs.dialog.init($utils.clone(item.id)) ;$refs.dialog.isShow = true"/>
          <a-icon key="delete" type="delete" @click="del(item)"/>
        </template>
        <a-card-meta :title="item.name" :description="`介绍: ${item.memo}`"/>
        <a-card-meta :description="`标签: ${item.listTagNames==null?'':item.listTagNames}`"/>
        <a-card-meta :description="`版本: ${item.appVersion}`"/>
        <a-card-meta :description="`开通数: ${item.qty}`"/>
      </a-card>
    </div>

    <save-app ref="dialog" @save="reloadList"></save-app>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SaveApp from './components/addEditApp'

export default {
  components: {
    Breadcrumb,
    SaveApp
  },
  data () {
    return {
      activeKey: 'ALL',
      /* 模拟标签 */
      appTags: [
        {
          tagCode: 'ALL',
          tagName: '全部'
        },
        {
          tagName: '电商',
          tagCode: 'DS'
        },
        {
          tagName: '内容',
          tagCode: 'NR'
        }
      ],
      breadList: [
        {
          name: '应用管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        name: '',
        code: ''
      },
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    callback (key) {
      this.form.tagCode = key
      this.activeKey = key
      this.queryList()
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: '',
        code: ''
      }
      this.activeKey = 'ALL'
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = this.form
      const result = await this.$store.dispatch('http', {
        api: 'queryAppList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.list = result
    },
    del (scope) {
      this.$store.dispatch('showConfirm', {
        message: '确认删除该应用吗？',
        yes: async _ => {
          scope.isLoading = true
          const res = await this.$store.dispatch('http', {
            api: 'removeApp',
            query: {
              id: scope.id
            },
            complete: () => {
              scope.isLoading = false
              this.reloadList()
            }
          })
          if (res) {
            this.$store.dispatch('showToast', { message: '删除分类成功~' })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}

/*  justify-content: space-around; */
.app-container {
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
}

.app-card {
  width: 260px;
  margin-bottom: 10px;
  margin-left: 10px;
}
</style>
