<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="应用ID" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="应用名称" prop="name">
        <a-input v-model="form.name" placeholder="给目标起个名字"/>
      </a-form-model-item>
      <a-form-model-item label="应用编号" prop="code">
        <a-input v-model="form.code" placeholder="编号不能重复"/>
      </a-form-model-item>
      <!--      <p style="font-size: 1px;color: gray">标准URL，租户可配置三级域名 *.cms.zaosl.com，再租户开通相关服务的时候设置即可</p>-->
      <a-form-model-item label="访问URL" prop="preUrl">
        <a-input v-model="form.preUrl"/>
      </a-form-model-item>
      <a-form-model-item label="最新版本号" prop="appVersion">
        <a-input v-model="form.appVersion" placeholder="请输入版本号"/>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="listTag">
        <a-select v-model="form.listTagsCode"
                  @change="onchange"
                  placeholder="选择标签，可支持多选" mode="multiple">
          <a-select-option v-for="item in options" :key="item.tagCode">
            {{ item.tagName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="介绍描述" prop="memo">
        <a-textarea v-model="form.memo" placeholder="请输入你的介绍描述">
        </a-textarea>
      </a-form-model-item>
      <a-form-model-item label="应用封面图" prop="iconUrl" style="margin-bottom:0">
        <a-input v-model="form.icon" style="display:none"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      tempId: undefined,
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      // 模拟标签
      options: [
        {
          tagName: '电商',
          tagCode: 'DS'
        },
        {
          tagName: '内容',
          tagCode: 'NR'
        }
      ],
      form: {
        id: '',
        name: '',
        code: '',
        preUrl: '',
        appVersion: '',
        listTag: [],
        memo: '',
        listTagsCode: []
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }],
        code: [{
          required: true,
          message: '编号不能为空',
          trigger: 'change'
        }],
        appVersion: [{
          required: true,
          message: '版本号不能为空',
          trigger: 'change'
        }],
        preUrl: [{
          required: true,
          message: '访问url不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  watch: {
    isShow (val) {

    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
      this.tempId = item
      this.getAppInfo(this.tempId)
    },
    // 获取详情.
    async getAppInfo (tempId) {
      const res = await this.$store.dispatch('http', {
        api: 'queryAppInfo',
        query: {
          id: tempId
        },
        complete: () => {
        }
      })
      this.form = res
      if (res.listTagsCode === null) {
        this.form.listTagsCode = []
      }
    },
    /* 选择标签组装数据 */
    onchange (e) {
      this.form.listTag = []
      this.options.forEach(item => {
        console.log('item', item)
        e.forEach(item2 => {
          if (item.tagCode === item2) {
            this.form.listTag.push(item)
          }
        })
      })
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        id: undefined,
        name: '',
        code: '',
        preUrl: '',
        appVersion: '',
        listTag: [],
        memo: ''
      }
    },
    /* 根据code去重 */
    unique (arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr.tagCode) && res.set(arr.tagCode, 1))
    },
    // 提交保存.
    async determine () {
      if (this.form.id) {
        this.onchange(this.form.listTagsCode)
      }
      console.log('提交数据：', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'saveApp'
          if (this.form.id) {
            api = 'updateApp'
            params.id = this.form.id
          } else {
            delete params.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '编辑' : '新增') + '应用成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
